/**
 * Provide anything into window object
 * @param {string} key - field name
 * @param {any} value - field value
 * @return {value}
 */
export function provideIntoWindow(key, value) {
  // eslint-disable-next-line
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line
    window[key] = value;
  }

  return value;
}
