var $_CONFIG = $_CONFIG || {};
$_CONFIG.gsapVersion = '3';

$_CONFIG.sport = true;
$_CONFIG.sportVersion = 3;
$_CONFIG.sportId = 'rabona';
$_CONFIG.sportFixedTop = 140;

$_CONFIG.useSvgSprite = false;
$_CONFIG.scripts = $_CONFIG.scripts || [];
$_CONFIG.domain = 'https://rabona.devv2.space';
$_CONFIG.siteDomain = 'https://rabona.com';
$_CONFIG.cdn = '/joxi';
$_CONFIG.mobile = false;

$_CONFIG.apiVersion = 'v2';

$_CONFIG.pwa = true;
$_CONFIG.appName = 'Rabona';
$_CONFIG.themeColor = '#1E2737';

$_CONFIG.siteName = 'rabona';

$_CONFIG.libphonenumber = true;

$_CONFIG.hotjar = {
  customHeatMapEnabled: true,
};

$_CONFIG.redirectToNotFound = false;
//- ПЛЕЙСИ ДЛЯ БАНЕРІВ І СЛАЙДЕРІВ
//- в шаблоні вказувати саме об'єкт конфіга.
$_CONFIG.places = {
  banner: {
    login: `'login'`,
    home1: `'sport_main_banner'`,
    home2: `'paynplay_pay'`,
    home3: `'sport_promotion'`,
    home4: `'live_promotion'`,
    home5: `'casino_promotion'`,
    home6: `'collection_main_banner1'`,
    home7: `'homepage_banner_promos'`,
    home8: `'homepage_banner_crab'`,
    casino_main: `'casino_main_banner'`,
    card_guide: `'collection_guide'`,
    season_sport_tournament: `'season_sport_tournament'`,
    home_ao: `'home_page_ao_banner'`,
    easter_main: `'easter_banner'`,
  },
  slider: {
    gamehall1: `'game_hall'`,
  },
  promo: {
    registration: `'welcome_bonus'`,
    home1: `'sport_main'`,
    home2: `'casino_main'`,
    card_guide: `'collection_guide'`,
  },
  icons: {
    footer1: `'footer_pay'`,
    paynplay: `'paynplay_pay'`,
  },
};

$_CONFIG.sportBanners = {
  topCenter: {
    iframe: `/sport-banners-slider/`,
    options: {
      pages: ['prelive'],
      height: '190px',
    },
  },
  bottomRight: {
    iframe: `${$_CONFIG.cdn}/js/crab/sdk/views/widget-sport.html?build=${Date.now()}`,
    options: {
      height: '486px',
    },
  },
  // aboveHighlights: {
  //   iframe: '/sport-banners-slider/center-cta/',
  //   options: {
  //     height: '150px',
  //   }
  // }
};

$_CONFIG.googleAnalytics = {
  appId: 'UA-151907223-1',
  trackAs: 'rabona',
};

$_CONFIG.sportCategory = {
  football: 'football',
  basketball: 'basketball',
  hockey: 'hockey',
};

$_CONFIG.symplify = true;
//[ 'football' , 'basketball' , 'hockey' ];

if (typeof window !== 'undefined') {
  window.$_REGISTRATION = {};
  window.$_REGISTRATION.promotions = {
    category: 'welcome_bonus',
    active: 0,
  };
}

$_CONFIG.sentry = {
  enabled: true,
  key: '593b6383a70422b7287ff5db450e90c7',
  id: '151',
  project: 'rabona',
};

export { $_CONFIG };
